import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyDZMGEp6h2vavh1holGpiKpVhNZ-FU8qiw",
    authDomain: "shared-office-landing.firebaseapp.com",
    projectId: "shared-office-landing",
    storageBucket: "shared-office-landing.appspot.com",
    messagingSenderId: "594681465245",
    appId: "1:594681465245:web:9cd09d249a86c11ad2c774",
    measurementId: "G-Y3VRFD3GSJ"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const analytics = getAnalytics(app);

export { db };
