import React, { useState } from 'react';
import { addDoc, collection } from "firebase/firestore";
import { db } from '../firebaseConfig';
import './SignUpForm.css';

const SignUpForm: React.FC = () => {
    const [email, setEmail] = useState<string>('');
    const [interest, setInterest] = useState<string>('');
    const [success, setSuccess] = useState<boolean>(false);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            // Add email and interest type to Firestore collection
            await addDoc(collection(db, "signups"), { email, interest, createdAt: new Date() });
            setSuccess(true);
        } catch (error) {
            console.error("Error adding document: ", error);
            setSuccess(false);
        }
    };

    return (
        <section className="signup-section">
            <h2>Stay Updated on Our Launch</h2>
            <p>Sign up to receive notifications and let us know what you’re interested in!</p>

            {success ? (
                <p>Thank you for signing up!</p>
            ) : (
                <form onSubmit={handleSubmit}>
                    <label>
                        <strong>Email:</strong>
                    </label>
                    <input
                        type="email"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Enter your email"
                        required
                    />

                    <label>
                        <strong>I'm interested in:</strong>
                    </label>
                    <select
                        name="interest"
                        value={interest}
                        onChange={(e) => setInterest(e.target.value)}
                        required
                    >
                        <option value="">Select an option</option>
                        <option value="registering a desk">Registering a Desk to Share</option>
                        <option value="finding a desk">Finding a Desk for Short-Term Use</option>
                    </select>

                        <button className="notify-button" type="submit">Notify Me</button>
                </form>
            )}
        </section>
    );
};

export default SignUpForm;