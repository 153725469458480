import React from 'react';
import './Hero.css';

const Hero: React.FC = () => {
    return (
        <section className="hero">
            <h1>Welcome to Hive Office Share</h1>
            <p>Connecting desk providers with individuals looking for short-term desk space. Be the first to know when we launch!</p>
        </section>
    );
};

export default Hero;