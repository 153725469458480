import React from 'react';
import './Header.css';
import logo from '../assets/images/hive_logo_white.png';

const Header: React.FC = () => {
    return (
        <header className="header">
            <div className="logo">
                <img src={logo} alt="Hive Office Share Logo" />
            </div>
            {/* <section className="hero">
                <h1>Welcome to Hive Office Share</h1>
                <p>Connecting desk providers with individuals looking for short-term desk space. Be the first to know when we launch!</p>
            </section> */}
        </header>
    );
};

export default Header;